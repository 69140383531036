/*
 * @Author: fengyanjing
 * @Date: 2020-06-02 14:05:28
 * @Description:实力瘦公用的推荐奖详情
 * @FilePath: /frontend/src/pages/rebate/shilishou/common/rebate-release-management/index.js
 */
import CompRebateLayoutThree from "@/components/rebate/comp-rebate-layout-three/index.vue";
import RebateTable from "@/components/rebate/rebate-table/index.vue";
import rebateMixin from "@/mixins/shilishou-rebate";
import { getReleaseBonus } from "@/service/rebate/shilishouRebate";
import Rmb from "@/components/common/rmb/index.vue";

export default {
  name: "strategy-rebate-home",
  mixins: [rebateMixin],
  components: {
    CompRebateLayoutThree,
    RebateTable,
    Rmb,
  },
  data() {
    return {};
  },
  computed: {
    //  合伙人展示的行名
    partnerColumns() {
      const columns = [
        {
          label: "团队",
          key: "name",
          render(name) {
            return `${name}团队`;
          },
        },
        {
          label: "团队团购金额",
          isRmb: true,
          key: "allTeamTotal",
        },
        {
          label: "团队折扣奖励",
          isRmb: true,
          key: "teamDiscount",
        },
      ];
      return columns;
    },
    //  间接推荐展示的行名
    founderColumns() {
      const columns = [
        {
          label: "团队",
          key: "name",
          render(name) {
            return `${name}团队`;
          },
        },
        {
          label: "团队团购金额",
          isRmb: true,
          key: "allTeamTotal",
        },
        {
          label: "团队折扣奖励",
          isRmb: true,
          key: "teamDiscount",
        },
      ];
      return columns;
    },
    //  合伙人列表
    partnerList() {
      if (this.rebateInfo) {
        return this.rebateInfo.partnerList || [];
      }
      return [];
    },
    //  间接推荐奖
    founderList() {
      if (this.rebateInfo) {
        if (this.rebateInfo) {
          return this.rebateInfo.originatorList || [];
        }
        return [];
      }
    },
    //  合伙人小计
    partnerBonusSum() {
      return this.partnerList.reduce((sum, item) => {
        return Number(sum) + Number(item.teamDiscount);
      }, 0);
    },
    founderBonusSum() {
      return this.founderList.reduce((sum, item) => {
        return Number(sum) + Number(item.teamDiscount);
      }, 0);
    },
  },
  methods: {
    //  初始化页面
    init() {
      const {
        startTime,
        endTime,
        agentId,
        userInfo: { levelId },
      } = this;
      getReleaseBonus({
        startTime,
        endTime,
        userId: agentId,
        levelId,
      }).then((data) => {
        this.rebateInfo = data;
      });
    },
  },
};
